.users {
    background-color: $divider;
    display: grid;
    grid-template-columns: minmax(300px, 1fr) 3fr;
    overflow-y: auto;
    column-gap: 1px;
    grid-template-areas: 'tools main';
    height: 100%;

    &--admin {
        grid-template-columns: 1fr;
        grid-template-areas: 'main';
    }
}

.users__tools {
    grid-area: tools;
    overflow-y: auto;
    background-color: $fadedBackground;
    padding: 14px 20px;
    display: flex;
    flex-direction: column;
}

.users__tools-buttons {
    margin-top: auto;
}

.users__tools-button {
    width: 100%;
}

.users__callout {
    border-radius: 0;
}

.users__main {
    background-color: white;
    overflow: auto;
    grid-area: main;
}

.users__modal {
    position: relative;
    grid-area: main;
    z-index: 10;
}
