.users-table {
    width: 100%;

    .bp5-control {
        margin: 0;
    }
}

.users-table__row {
    td {
        border-bottom: 1px solid $mutedBackground;
    }

    &--selected {
        background-color: $fadedBackground;
    }
}

.users-table__notifications--on,
.users-table__available--yes {
    color: $success;
}

.users-table__notifications--off {
    color: $mutedText;
}

.users-table__available--no {
    color: $danger;
}

.users-table__notifications-label,
.users-table__available-label {
    font-weight: bold;
    margin-left: 5px;
}

.users-table__active-row {
    background-color: $fadedBackground;
}
