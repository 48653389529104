.v2__form-group.v2__form-time-input-group .bp5-form-content {
    display: flex;
}

.v2__form-group .v2__form-time-input {
    width: min-content;
    margin-right: 10px;

    &--full {
        width: 100%;
    }
}

.v2__form-group .v2__form-time-input .bp5-timepicker-input-row {
    width: 100%;

    background-color: $color-light-50;
    box-shadow: 0 0 0 1px $color-border-main-50;
    transition: box-shadow $transition-mouse-over;

    border: none;
    outline: none;
    border-radius: $border-radius-sm;

    &:hover {
        background-color: $color-light-50;
        box-shadow: 0 0 0 1px $color-border-main;
    }

    &:focus-within {
        box-shadow: 0 0 0 1px $color-blue;
    }
}

.v2__form-group .v2__form-time-input .bp5-timepicker-input-row,
.v2__form-group .v2__form-time-input .bp5-timepicker-arrow-row {
    display: flex;
}

// INPUTS

.v2__form-group .v2__form-time-input .bp5-timepicker-divider-text,
.v2__form-group .v2__form-time-input input.bp5-timepicker-input {
    height: $input-height;

    @include font-input;

    box-shadow: none;
}

.v2__form-group .v2__form-time-input .bp5-timepicker-divider-text {
    flex: 0 0 auto;
    width: 4px;
}
.v2__form-group .v2__form-time-input input.bp5-timepicker-input {
    flex: 0 0 auto;
    padding: 4px 8px;
    width: 34px;

    &:last-of-type {
        flex-grow: 1;
        text-align: left;
    }

    &:focus {
        box-shadow: none;
    }

    &:disabled {
        color: $color-grey;
    }

    &.bp5-intent-danger {
        color: $color-red;
    }
}

// ARROWS

.v2__form-group .v2__form-time-input .bp5-timepicker-arrow-button {
    flex: 0 0 auto;
    padding: 4px 8px;
    width: 32px;

    color: $color-text-main;
    transition: opacity $transition-mouse-over;
    opacity: 0.75;

    + .bp5-timepicker-arrow-button {
        margin-left: 4px;
    }

    &:hover,
    &:focus {
        outline: none;
        opacity: 1;
    }

    &.bp5-timepicker-millisecond {
        width: 40px;
    }
}

// AM-PM

.v2__form-group .v2__form-time-input--am-pm {
    grid-template-areas:
        'timepicker-arrow1 timepicker-arrow1'
        'timepicker-input timepicker-select'
        'timepicker-arrow2 timepicker-arrow2';
    grid-template-columns: 1fr 64px;
    grid-template-rows: 26px 40px 26px;
    display: grid;

    .bp5-timepicker-arrow-row:first-of-type {
        grid-area: timepicker-arrow1;
    }
    .bp5-timepicker-input-row {
        grid-area: timepicker-input;
    }
    .bp5-timepicker-ampm-select {
        grid-area: timepicker-select;
    }
    .bp5-timepicker-arrow-row:last-of-type {
        grid-area: timepicker-arrow2;
    }
}

// SELECTOR

.v2__form-group .v2__form-time-input .bp5-timepicker-ampm-select select {
    width: 100%;
    height: $input-height;

    @include font-input;

    background-color: $color-light-50;
    box-shadow: 0 0 0 1px $color-border-main-50;
    transition: box-shadow $transition-mouse-over;

    border: none;
    outline: none;
    border-radius: $border-radius-sm;

    &:hover {
        background-color: $color-light-50;
        box-shadow: 0 0 0 1px $color-border-main;
    }

    &:focus {
        box-shadow: 0 0 0 1px $color-blue;
    }
}

.v2__form-group .v2__form-time-input .bp5-icon {
    top: 12px;
    right: 8px;
}

.v2__form-group--error .v2__form-time-input .bp5-timepicker-input-row {
    box-shadow: 0 0 0 1px $color-red;
}

.v2__form-group {
    &--ml {
        margin-left: 35px;
    }
}
