.suggest {
  &__popover {
    .bp5-popover-content {
      padding-top: 10px;
      padding-bottom: 5px;
      max-height: 335px;
      overflow: auto;

      &:before {
        content: '';
        padding: 10px;
      }
    }

    &--functions {
      .bp5-popover-content {
        &:before {
          content: 'Functions with similar name:';
          font-style: italic;
        }
      }
    }

    &--roles {
      .bp5-popover-content {
        &:before {
          content: 'Roles with similar name:';
          font-style: italic;
        }
      }
    }

    &--departments {
      .bp5-popover-content {
        &:before {
          content: 'Departments with similar name:';
          font-style: italic;
        }
      }
    }

    &--costCentres {
      .bp5-popover-content {
        &:before {
          content: 'Cost centres with similar name:';
          font-style: italic;
        }
      }
    }

    &--funding-pools {
      .bp5-popover-content {
        &:before {
          content: 'Funding pools with similar name:';
          font-style: italic;
        }
      }
    }

    &--agencies {
      .bp5-popover-content {
        &:before {
          content: 'Agencies with similar name:';
          font-style: italic;
        }
      }
    }

    &--locations {
      .bp5-popover-content {
        &:before {
          content: 'Locations with similar name:';
          font-style: italic;
        }
      }
    }
  }
}
