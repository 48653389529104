.v2__form-group .v2__form-checkbox-input {
    user-select: none;
    align-items: center;
    display: inline-flex;

    &--left {
        position: absolute;
        left: 45px;
    }
}

.v2__form-group .v2__form-checkbox-input input ~ .bp5-control-indicator {
    margin-top: 0;
    width: 24px;
    height: 24px;

    font-size: 16px;
    line-height: 16px;

    color: $color-text-main;
    background-color: $color-light-50;
    box-shadow: 0 0 0 1px $color-border-main-50;
    transition: background-color $transition-mouse-over, box-shadow $transition-mouse-over;

    border: none;
    outline: none;
    border-radius: $border-radius-sm;

    align-items: center;
    justify-content: center;
    display: inline-flex;
}
.bp5-control-indicator--small .v2__form-checkbox-input input ~ .bp5-control-indicator {
    width: 16px;
    height: 16px;
}

.v2__form-group .v2__form-checkbox-input:hover input:not(:checked) ~ .bp5-control-indicator {
    background-color: $color-light-50;
    box-shadow: 0 0 0 1px $color-border-main;
}

.v2__form-group .v2__form-checkbox-input:active input:not(:checked) ~ .bp5-control-indicator {
    box-shadow: 0 0 0 1px $color-blue;
}

.v2__form-group .v2__form-checkbox-input input:checked ~ .bp5-control-indicator {
    background-color: $color-blue;
    box-shadow: 0 0 0 1px $color-blue;
}

.v2__checkbox {
    display: flex;
    align-items: center;
}
