.users-list {
    list-style: none;
    padding: 0;
}

.users-list__item {
    margin: 10px 0;
    padding: 0;
}

.users-list__status--enabled {
    color: $success;
}

.users-list__status--disabled {
    color: $danger;
}

.users-list__status-label {
    margin-left: 5px;
}
