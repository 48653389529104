@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.footer {
    color: $mutedText;
    display: flex;
    padding: 10px 70px 10px 15px;
    height: 100%;
    align-items: center;
}

.with-beamer {
    padding-right: 130px;
}

.footer__org {
    margin-right: 8px;
}

.footer__loading-spinner {
    display: flex;
    align-items: baseline;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.footer__info {
    margin-left: auto;
}

.footer__dialog {
    padding: 20px 20px 20px;
}

#beamerSelector {
    height: 50px;
    width: 50px;
}
