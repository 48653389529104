.ucr__controls-container {
    display: flex;
    flex-wrap: wrap-reverse;
    z-index: 6;
    background-color: $lightGreyBackground;
}

.ucr__main-filters {
    z-index: 6;
}

.ucr__controls {
    padding: 20px 20px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ucr__controls-urgent-warning {
    display: flex;
    flex-grow: 4;
    padding: 20px 0 0 20px;
}

.ucr__controls-button {
    margin-left: 10px;
    &--map-zoom {
        background: $color-white;
        color: $color-black;
        fill: $color-black;
        border: 0px;
        margin-top: 5px;
        text-transform: none;
        appearance: none;
        position: absolute;
        cursor: pointer;
        overflow: hidden;
        top: 5px;
        left: 50%;
        transform: translate(-50%);
        padding: 10px;
        margin-right: 0;
        border-radius: 2px;
        &:hover {
            background: $color-white;
            opacity: 0.9;
        }
    }
}
