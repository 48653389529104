.absence-table {
    background-color: $lightGreyBackground;
    color: #989898;
    border: 1px solid #d5dbde;
    padding: 0 20px;

    tr:hover td {
        background-color: #edf0f0;
    }

    .common-table__row {
        position: relative;

        &:after {
            content: '';
            border-bottom: 1px solid #d5dbde;
            height: 1px;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
        }

        td {
            vertical-align: middle;
        }

        .bp5-control.bp5-checkbox {
            margin-bottom: 0;
        }
    }

    .absence-name-column {
        font-weight: 600;
        color: #014d7e;
    }

    &.bp5-html-table th {
        font-weight: 600;
        color: #575757;
        text-align: left;
        vertical-align: middle;
        padding: 12px;

        &.sorted-header {
            padding: 12px 0;

            .bp5-button {
                position: relative;
                justify-content: flex-start;

                .bp5-button-text {
                    width: auto;
                }

                &:after {
                    content: '';
                    margin-left: 5px;
                    width: 12px;
                    height: 12px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 16 16'%3E%3Cpath fill='%23A7A7A7' fill-rule='evenodd' d='M5 7h6c.55 0 1-.45 1-1 0-.28-.11-.53-.29-.71l-3-3C8.53 2.11 8.28 2 8 2s-.53.11-.71.29l-3 3C4.11 5.47 4 5.72 4 6c0 .55.45 1 1 1zm6 2H5c-.55 0-1 .45-1 1 0 .28.11.53.29.71l3 3c.18.18.43.29.71.29s.53-.11.71-.29l3-3c.18-.18.29-.43.29-.71 0-.55-.45-1-1-1z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
                }
            }
        }

        .bp5-control.bp5-checkbox {
            margin-bottom: 0;
        }
    }

    &.bp5-html-table td {
        max-width: 100px;
    }

    .table-row-status {
        &--gray {
            color: #7d9099;
            font-weight: 500;
        }

        &--green {
            font-weight: 500;
            color: #38b552;
        }
    }
}

.absence-header {
    .absence-header-row {
        display: flex;
        justify-content: space-between;
    }

    .buttons-section {
        display: flex;

        .margin5 {
            margin: 5px;
        }
    }
}

.absences-header-filters {
    padding: 15px 20px;
    width: 100%;
    min-height: 60px;

    background-color: $color-white;
}

.absences-header-filtersContainer {
    background-color: $color-white;
    transition: margin-top $transition-appearance, opacity $transition-appearance;
    align-items: center;
    justify-content: flex-end;
    display: flex;

    &:first-child {
        position: relative;
        z-index: 1;
    }

    &--hide {
        margin-top: -20px;
        opacity: 0;
    }

    &--show {
        margin-top: 20px;
        opacity: 1;
    }

    .absence-filter-select {
        min-width: 200px;
        margin-left: 10px;
    }
}
