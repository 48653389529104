.ucr-staff-alert-dialog__message-timestamp {
    font-weight: bold;
}

.ucr-staff-alert-dialog__message {
    font-family: $font-family-names;
}

.ucr-staff-alert-dialog__map {
    width: 100%;
    aspect-ratio: 2;
}

.ucr-staff-alert-dialog__job-table {
    text-align: left;
}
