$job-status-available: #000000;
$job-status-pending: #000000;
$job-status-accepted: #9e9e9e;
$job-status-current: #2196f3;
$job-status-arrived: #e91e63;
$job-status-completed: #4caf50;
$job-status-aborted: #f44336;
$job-status-withdrawn: #d9822b;

/**
    If you change colour here please change in helpers/colors module also.
**/

.job-status-background {
    &__available {
        background-color: $job-status-available;
    }
    &__pending {
        background-color: $job-status-pending;
    }
    &__accepted {
        background-color: $job-status-accepted;
    }
    &__current {
        background-color: $job-status-current;
    }
    &__arrived {
        background-color: $job-status-arrived;
    }
    &__completed {
        background-color: $job-status-completed;
    }
    &__aborted {
        background-color: $job-status-aborted;
    }
    &__withdrawn {
        background-color: $job-status-withdrawn;
    }
}
