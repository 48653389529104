.actions-drop-down__drop-down,
.quick-actions-drop-down__drop-down {
    padding: 2px;
}

.actions-drop-down__wrapper,
.quick-actions-drop-down__wrapper {
    padding-right: 3px;
}

.actions-drop-down__button,
.quick-actions-drop-down__button {
    width: 100%;
    margin: 2px;
}
