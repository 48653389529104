.ucr-listview__container {
    display: inline-block;
    width: 100%;
    padding-bottom: 50px;
}

.ucr-listview__main {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    overflow: scroll;
    &--min-height {
        min-height: 700px;
        padding-bottom: 80px;
    }
}

.ucr-listview__list-row {
    width: 100%;
    & > div:last-child {
        border-right: 1px solid $color-break-main;
    }
    & > th {
        border: 1px solid $color-border-main;
        text-align: left;
        padding: 10px;
        &:hover {
            cursor: pointer;
        }
    }
    & > td {
        padding: 11px;
        border: 1px solid $color-border-main;
    }
    &--focused {
        background-color: $color-blue-bg;
        color: $color-text-main;
    }
    &--sick {
        background-color: rgba($color-orange, 0.1);
    }
    &--patient_alert {
        color: $color-white;
        background-color: $color-task-bg-alert;
    }
    &--staff_alert {
        background-color: mix($color-white, $color-red, 10%);
        color: $color-white;
    }
    &--sticky {
        position: sticky;
        top: 0px;
        z-index: 1;
    }
    &--content {
        cursor: pointer;
    }
    &--content:hover {
        color: $color-text-main;
        background-color: rgba($color-blue-bg, 0.5);
    }
    &--patient_alert > td .ucr-listview__icon {
        fill: $color-white;
    }
    &--staff_alert > td .ucr-listview__icon {
        fill: $color-white;
    }
    &--content:hover > td .ucr-listview__icon {
        fill: $color-grey;
    }
    &--center {
        text-align: center;
    }
}

.ucr-listview__table {
    border-collapse: collapse;
    width: 100%;
}

.ucr-listview__header {
    background-color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    color: $mutedText;
}

.ucr_listview__empty {
    background-color: $color-task-bg-urgent;
    font-size: $font-size-h3;
    text-align: center;
    padding-top: 50px;
    width: 100%;
    padding-bottom: 50px;
}

.ucr-listview__status {
    border-radius: $border-radius-md;
    display: block;
    padding: 5px;
    color: rgba($color-white, 0.8);
    text-align: center;
    &--success {
        background-color: $color-task-border-completed;
    }

    &--error {
        background-color: $color-task-border-aborted;
    }
}

.ucr-listview__icon {
    height: 25px;
    width: 25px;
    fill: $color-grey;
    display: inline;
    position: relative;
    word-wrap: normal;

    &--alert {
        fill: $color-red;
    }
}

.ucr-listview__child {
    &--uppercase {
        text-transform: capitalize;
    }
}

.ucr-listview__popover {
    & .bp5-popover2-content {
        color: $color-black;
        background-color: $color-white;
    }
}

.ucr-listview__containerWidth {
    min-width: 1660px;
    border-collapse: collapse;
}

.whiteSpace__nowrap {
    white-space: nowrap;
}

.ucr-listview__s1-status {
    border-radius: $border-radius-md;
    display: block;
    padding: 5px;
    color: rgba($color-white, 0.8);
    text-align: center;

    &--na {
        background-color: $color-task-border-accepted;
    }
    &--loading {
        background-color: $color-task-border-accepted;
    }
    &--synced {
        background-color: $color-task-border-completed;
    }
    &--not_synced {
        background-color: $color-task-border-aborted;
    }
}
