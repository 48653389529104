.map-container {
    z-index: 0;
    position: relative;
    flex: 1;
    height: 100%;
}

.map-container > div:last-child {
    height: 100%;
}
