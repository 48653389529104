.warning-blocks {
  background: #fff;

  &--sp {
    padding: 0 20px 20px;
  }

  .warning-block {
    --warning-bg: #facf89;
    --warning-bc: #f9b190;
    --warning-br: 5;
    padding: 10px 20px;
    background: var(--warning-bg, #fff);
    border: 1px solid var(--warning-bc, #fff);
    border-radius: calc(var(--warning-br) * 1px);

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .v2-callout--warning {
    padding: 10px;
    background-color: #facf89;
  }
}
