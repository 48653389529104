.table-row-actions {
  position: relative;
  color: var(--text-primary);
  cursor: pointer;

  .bp5-tooltip2 .bp5-popover2-content {
    background: #fff;
    padding: 0;
  }


  &__list {
    display: grid;
  }

  .table-row-action {
    display: grid;
    grid-template-columns: 1fr 15px;
    gap: 10px;
    color: #333;
    transition: all 0.2s ease;
    cursor: pointer;
    padding: 10px;

    &:hover {
      color: #232323;
      background: #f5f5f5;
    }

    &--duplicate {
      cursor: copy;
    }
  }
}
