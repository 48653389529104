.placeholder-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.s1-connected-clients__main {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    overflow: auto;
    &--min-height {
        min-height: 700px;
        padding-bottom: 80px;
    }
}

.s1-connected-clients__status-filter-container {
    display: flex;
    justify-content: start;
    margin-top: 10px;
    margin-bottom: 20px;
    p {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-right: 10px;
        margin-bottom: 0;
    }
}

.s1-connected-clients__containerWidth {
    width: 100%;
    border-collapse: collapse;
}

.s1-connected-clients__table {
    border-collapse: collapse;
    width: 100%;
}

.s1-connected-clients__header {
    background-color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    color: $mutedText;
}

.s1-connected-clients__status-column {
    max-width: 300px;
}

.s1-connected-clients__empty {
    font-size: $font-size-h3;
    text-align: center;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    &--error {
        background-color: $color-status-bg-error;
    }
    &--loading {
        background-color: $color-status-bg-loading;
    }
    &--empty {
        background-color: $color-status-bg-empty;
    }
}

.s1-connected-clients__status {
    border-radius: $border-radius-md;
    display: block;
    padding: 5px;
    color: rgba($color-white, 0.8);
    text-align: center;
    &--connected {
        background-color: $color-status-border-connected;
    }
    &--disconnected {
        background-color: $color-status-border-disconnected;
    }
    &--warning {
        background-color: $color-status-border-warning;
    }
}
.s1-connected-clients__list-row {
    width: 100%;
    & > div:last-child {
        border-right: 1px solid $color-break-main;
    }
    & > th {
        border: 1px solid $color-border-main;
        text-align: left;
        padding: 10px;
        &:hover {
            cursor: pointer;
        }
    }
    & > td {
        min-width: 200px;
        padding: 11px;
        border: 1px solid $color-border-main;
    }
    &--focused {
        background-color: $color-blue-bg;
        color: $color-text-main;
    }
    &--status-warning {
        color: $color-white;
        background-color: $color-task-bg-alert;
    }
    &--status-connected {
        background-color: mix($color-white, $color-green, 25%);
        color: $color-white;
    }
    &--status-disconnected {
        background-color: mix($color-white, $color-grey, 25%);
        color: $color-white;
    }
    &--sticky {
        position: sticky;
        top: 0px;
        z-index: 1;
    }
    &--content {
        cursor: pointer;
    }
    &--content:hover {
        color: $color-text-main;
        background-color: rgba($color-blue-bg, 0.5);
    }
    &--center {
        text-align: center;
    }
}
