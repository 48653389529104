.v2__form {
    &--with-border {
        border: 1px solid $color-border-main;
    }
}

.v2__form-block {
    padding: 20px;
    grid-gap: 10px 20px;
    grid-template-columns: repeat(12, 1fr);
    display: grid;

    &:not(:last-child) {
        border-bottom: 1px solid $color-border-main;
    }
    &--spacer-top {
        margin-top: 30px;
    }
}

.v2__form-no-data {
    background-color: #eff1f2;
    grid-column: 1 / 13;
    border-radius: 6px;
    padding: 10px;

    &--title {
        color: $color-blue-dark;
    }
}

.v2__form-block--no-padding {
    padding: 0px;
}

.v2__form-submit-button {
    margin: 10px 10px 0 0;
}
.v2__form-add-main-numbers-block {
    display: flex;
    align-self: center;
    width: 20px;
    height: 20px;
    margin-top: 25px;
}
.v2__form-add-numbers-block {
    display: flex;
    align-self: center;
    width: 20px;
    height: 20px;
}
.v2__form-add-numbers-button {
    display: flex;
    justify-content: center;
    width: 20px;
    height: 20px;
}
.v2__form-nhs-number {
    width: calc(100% - 125px);
    margin-right: 10px;
}

.v2__form-hidden-text {
    visibility: hidden;
}
.v2__form-lookup-btn {
    padding: 13px 10px;
    margin: 0 10px 0 0;

    &--mt {
        margin-top: 22px;
    }
}
.v2__form-btn-mt {
    margin-top: 22px;
}
.v2__form-btn-mb {
    margin-bottom: 20px;
}
.v2__form-remove-numbers-button {
    margin-left: 5px;
    background-color: $color-border-main;
    color: $color-grey;
    width: 20px;
    height: 20px;
}
.v2__form-remove-number-single-button {
    background-color: $color-border-main;
    color: $color-grey;
    width: 20px;
    height: 20px;
}
.v2__form-section {
    display: flex;
    flex-direction: column;
    padding: 20px;

    &:not(:last-child) {
        border-bottom: 1px solid $color-border-main;
    }

    .visit-details__notes {
        white-space: pre-wrap;
        overflow-wrap: anywhere;
    }
}

.v2__form-section-title {
    margin: 6px 0;
}

.v2__form-section-text-title,
.v2__form-section-radio-title {
    font-family: $font-family-main;
    font-weight: bold;
    color: $color-blue;
    position: relative;
}

.v2__form-section-text-title {
    margin-top: auto;
    margin-bottom: auto;
}

.v2__form-section-radio-title {
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;

    &--normal {
        font-weight: normal;
    }
}

.v2__form-section-radio-title,
.v2__form-section-radio-description {
    font-family: $font-family-main;
    color: $color-text-main;
    margin-top: auto;
    margin-bottom: auto;

    &--small {
        font-size: 10px;
    }
}

.v2__form-btn-section {
    display: flex;

    &--mb {
        margin-bottom: 15px;
    }
}
.v2__form-block-right-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 400px;
}
.v2__form-block-clear-patient-details {
    text-decoration: underline;
    cursor: pointer;
    margin-right: 20px;
    margin-top: 15px;
}
.v2__form-lookup-patient-block {
    padding: 20px 10px;
}
.v2__form-lookup-patient-nhsNumber-block {
    border-bottom: 1px solid;
    padding-bottom: 25px;
}
.v2__form-lookup-patient-nhsNumber {
    width: 40%;
}
.v2__form-lookup-patient-button {
    margin-top: 25px;
}

.v2__form-lookup-patient-bottom-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}
.v2__form-lookup-patient-table {
    display: flex;

    .v2__form-lookup-patient-table-element:last-of-type {
        background-color: red;
    }
}
.v2__form-lookup-patient-table-element {
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 50px;
    width: 100%;
}

.v2__form-lookup-patient-radio {
    margin-left: 13px;
    margin-bottom: 0;
    outline: none;
}
.v2__form-lookup-patient-title {
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 120px;
    font-weight: bold;
}
.v2__form-lookup-patient-checkbox {
    width: 20px;
    height: 20px;
    margin-left: 20px;
}
.v2__form-lookup-patient-checkbox-column {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    min-width: 50px;
}
.v2__form-lookup-patient-names-table-column-title {
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 50px;
    width: 100%;
    font-weight: bold;
    background-color: $information;
}
.v2__form-lookup-patient-table-column-number-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 150px;
    font-weight: bold;
}
.v2__form-lookup-patient-table-number-element {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    min-width: 150px;
}
.v2__form-lookup-patient-warning {
    color: $danger;
}

.pull-from-referral-wrap {
    .v2__dialog-header-right-block {
        height: 50px;
    }

    .v2__form-block {
        padding-top: 0;
        padding-bottom: 0;
    }

    .v2__form-group .v2__form-textarea-input {
        height: 78px;
    }
}
