table.bp5-html-table th.sorted-header {
    padding: 6px 0;

    &:first-child {
        padding-left: 10px;
    }

    &:last-child {
        padding-right: 10px;
    }
}
