.tabs {
    background-color: white;
    padding: 20px;
    display: flex;
    height: 100%;
    align-items: center;
}

.tabs__list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    width: 100%;
}

.tabs__item {
    flex: 1;
    margin-right: 10px;
    list-style: none;

    &:last-child {
        margin-right: 0;
    }
}

.tabs__link {
    padding: 9px 6px;
    display: block;
    text-align: center;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;

    &:hover {
        text-decoration: none;
    }

    .bp5-tag {
        color: white !important;
    }
}

.tabs__link--available {
    color: $available !important;
    border-color: $available;

    .bp5-tag {
        background-color: $available !important;
    }

    &.tabs__link--active {
        background: $available;

        .bp5-tag {
            color: $available !important;
        }
    }
}

.tabs__link--accepted {
    color: $accepted !important;
    border-color: $accepted;

    .bp5-tag {
        background-color: $accepted !important;
    }

    &.tabs__link--active {
        background: $accepted;

        .bp5-tag {
            color: $accepted !important;
        }
    }
}

.tabs__link--current {
    color: $current !important;
    border-color: $current;

    .bp5-tag {
        background-color: $current !important;
    }

    &.tabs__link--active {
        background: $current;

        .bp5-tag {
            color: $current !important;
        }
    }
}

.tabs__link--arrived {
    color: $arrived !important;
    border-color: $arrived;

    .bp5-tag {
        background-color: $arrived !important;
    }

    &.tabs__link--active {
        background: $arrived;

        .bp5-tag {
            color: $arrived !important;
        }
    }
}

.tabs__link--active {
    color: white !important;
    text-decoration: none !important;

    .bp5-tag {
        background-color: white !important;
    }
}

.tabs__tag {
    margin-left: 3px;
}

.tabs__has-error{
    &.bp5-tab{
        color: $danger-darker;
        &:after{
            content: "\26A0";
            margin-left: 5px;
        }
    }
}
