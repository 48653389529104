.withdraw-confirmation {
    top: -200px;
    right: 200px;
}

.abort-confirmation {
    bottom: 100px;
    right: 200px;
    z-index: 101;
    .shadow {
        .modal-shadow {
            background: rgba(0, 0, 0, 0.5);
            position: fixed;
            left: -100%;
            top: -100%;
            height: 100%;
            width: 100%;
            z-index: 100;
        }
    }
}

.requires-attention-highlight {
    margin-bottom: 20px;
}

.job__attention {
    color: $requireAttention;
}

.prefill__practice {
    background: $mutedBackground;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
}
