.practice-select__control {
    background-color: rgba(246, 247, 247, 0.5) !important;
}

.practice-select__multi-value {
    background-color: #5f6b7c !important;
    color: #ffffff !important;

    .practice-select__multi-value__label {
        color: #ffffff !important;
        font-size: 12px;
        font-weight: 400;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
            Open Sans, Helvetica Neue, blueprint-icons-16, sans-serif;
    }

    .practice-select__multi-value__remove {
        background: none;
        color: rgba(255, 255, 255, 0.7);
    }
}
