.info-bar {
    border-color: $divider;
    border-style: solid;
    overflow: hidden;
    z-index: 6;
}

.info-bar--top {
    border-width: 0 0 1px;
    z-index: 6;
    background-color: rgb(249, 236, 223);
}

.info-bar--bottom {
    border-width: 1px 0 0;
}


