.ucr__filters {
    padding: 15px 20px;
    width: 100%;
    min-height: 60px;

    background-color: $color-white;
}

.ucr__filters-container {
    background-color: $color-white;
    transition: margin-top $transition-appearance, opacity $transition-appearance;
    align-items: center;
    justify-content: space-between;
    display: flex;

    &:first-child {
        position: relative;
        z-index: 1;
    }

    &--hide {
        margin-top: -40px;
        opacity: 0;
    }
    &--show {
        margin-top: 20px;
        opacity: 1;
    }
}

.ucr__filters-left {
    align-items: center;
    justify-content: flex-start;
    display: flex;
}
.ucr__filters-right {
    align-items: center;
    display: flex;
}

.ucr__filters .ucr__filters-button,
.ucr__filters .ucr__filters-filter {
    margin-right: 15px;
}

.v2__form-multi-select-option {
    @include font-input;
    line-height: $line-height-h4;

    min-width: 150px;

    &--big {
        min-width: 190px;
    }


}
.v2__form-multi-select-option.bp5-active {
    color: white;
    background-color: $primary-darker;
}


.common-controls {
    padding: 15px 20px;
    width: 100%;
    min-height: 60px;
    display: flex;
    background-color: $color-white;

    &__left {
        display: flex;
        flex-grow: 2;
    }

    &__right {
        display: flex;
    }

    & .ucr__filters-filter-input {
        min-width: 150px;
        margin-left: 10px;
    }

    & .ucr__filters-filter-first {
        margin-left: 0;
    }
}

.ucr__side-panel-container .ucr__filters-panel-filter {
    margin-bottom: 10px;
}

.ucr__side-panel-container .ucr__filters-clear-filters {
    text-decoration: underline;
    padding-left: 0;
    color: $color-blue;

    &:hover {
        background: transparent;
        text-decoration: underline;
        color: $color-blue;
    }
}
