.bp5-form-content {
    min-height: 40px;

    // Make datepickers and time selectors full-width
    // and large inputs to fit with the other inputs' styles
    .bp5-popover-wrapper,bp5-popover-target
    .bp5-popover-target {
        width: 100%;
    }

    .bp5-popover-target .bp5-input-group .bp5-input,
    .bp5-timepicker .bp5-timepicker-input-row {
        height: 40px;
        line-height: 40px;
        font-size: 16px;
    }

    .bp5-numeric-input .bp5-input-group.bp5-large {
        width: 80%;
    }

    // Styling the contact number input plugin
    .react-tel-input input[type='tel'],
    .react-tel-input input[type='text'] {
        width: 100% !important;
        height: auto;
        padding: 5px 10px 5px 48px;

        .form-control {
            min-height: 40px;
        }
    }
}

.form-error-message {
    padding-top: 5px;
    color: $danger;
}

.no-padding {
    padding: 0;
}

.abort-confirmation-highlight {
    margin: 0 0 10px 0;
    font-size: 1.3em;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px !important;
    line-height: 1.3em;

    .bp5-icon {
        margin-right: 10px;
        position: initial !important;
    }
}

.form-footer {
    border-top: 1px solid $secondary;
    padding: 20px 0;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.bp5-form-group label.bp5-label {
    font-weight: 500;
}
