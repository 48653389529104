.list {
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    .empty-list {
        text-align: center;
    }
}
