.auth {
    width: 100%;
    padding: 15px;
    overflow-y: auto;

    @media (min-width: 992px) {
        padding-top: 50px;
    }
}

.auth__content {
    max-width: 400px;
    margin: 0 auto;
}

.auth__logo {
    height: 60px;
    margin: 0 auto 15px;
    display: block;
}

.auth-callout {
    margin-bottom: 15px;
}

.auth__submit-button {
    margin-top: 15px;
}

.auth__return-link {
    padding-top: 15px;
}

.auth__footer {
    padding-top: 30px;
}

.auth__login-controls {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;

    .bp5-control {
        margin: 0 10px 0 0;
    }
}

.auth__small-print {
    color: $mutedText;
    font-size: 13px;
    margin-top: 20px;
}
