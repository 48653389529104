.common-table__row {
  &--selected {
    background-color: $fadedBackground;
  }

  &--requires-attention {
    background-color: rgba($dangerBackground, 0.5);
  }

}

table.bp5-html-table.common-table .common-table__row--is-additional td, table.bp5-html-table.common-table .common-table__row--is-additional td .truncate {
  color: #613fd9;
}

.common-table__cell-no-wrap {
  white-space: nowrap;
}

.common-table__tag {
  margin: 0 5px 5px 0;
}

table.bp5-html-table.common-table th {
  font-weight: normal;

  &:first-child {
    width: 26px;

    .common-table--fill & {
      padding-left: 20px;
    }
  }

  .bp5-control {
    margin-bottom: 0;
  }
}

table.bp5-html-table.common-table.without-checkbox th {
  &:first-child {
    width: unset;
  }
}

table.bp5-html-table.common-table.common-table--fill th {
  &:first-child {
    padding-left: 20px;
  }
}

table.bp5-html-table.common-table td {
  color: $primary-darker;
  border-bottom: 1px solid $secondary;
  padding: 11px;

  .bp5-control {
    margin-bottom: 0;
  }

  &.common-table__cell-with-button {
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: right;
  }

  &.common-table__requires-attention {
    color: $danger;
  }

  &.common-table__icon-column {
    color: $success;
    padding-right: 0;
  }

  &.common-table__additional {
    color: $primary;
  }
}

table.bp5-html-table.common-table.common-table--fill th,
table.bp5-html-table.common-table.common-table--fill td {
  &:first-child {
    padding-left: 20px;
  }
}

table.bp5-html-table.common-table .common-table__row--grouped td {
  border-top: 5px solid $primaryBackground;
  border-bottom: 5px solid $primaryBackground;
  padding-top: 7px;
  padding-bottom: 6px;

  &:first-child {
    border-left: 5px solid $primaryBackground;
    padding-left: 15px;
  }

  &:last-child {
    border-right: 5px solid $primaryBackground;
    padding-right: 6px;
  }
}

table.bp5-html-table.common-table .common-table__row--grouped.common-table__row--start td,
table.bp5-html-table.common-table .common-table__row--grouped.common-table__row--middle td {
  border-bottom: 1px dotted $secondary;
  padding-bottom: 11px;
}

table.bp5-html-table.common-table .common-table__row--grouped.common-table__row--middle td,
table.bp5-html-table.common-table .common-table__row--grouped.common-table__row--end td {
  border-top: none;
  padding-top: 11px;
}

table.bp5-html-table.common-table .danger__row > td {
  color: $danger;
}

table.bp5-html-table.common-table .danger__cell {
  color: $danger;
}

table.bp5-html-table.common-table .warning__cell {
  color: $warning;
}

table.bp5-html-table.common-table .success__cell {
  color: $success;
}

table.bp5-html-table.common-table .warning__row > td {
  color: $warning;
}

.common-form {
  height: 100%;
}

table.bp5-html-table.common-table .document-row__info {
  margin-left: 10px;

  > span {
    margin-right: 10px;
    margin-bottom: 2px;
  }
}

.common-table__additional-icon {
  transform: scale(-1, 1);
}


.common-table.common-table--list-view {
  display: block;
  max-height: 49vh;
  overflow: auto;

  th {
    white-space: nowrap;
  }

  &.auto-fit-columns {
    thead, tbody {
      display: grid;
    }
    tr {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
    td {
      white-space: normal;
    }
    th:first-child {
      width: auto;
      padding-left: 0;
    }
  }
}

.common-table__row-flex {
  display: flex;

  .bp5-icon {
    margin-right: 5px;
  }
}
