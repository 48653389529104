.admin {
    display: grid;
    grid-template-rows: 80px 1fr;

    .bp5-tab[aria-selected="true"] {
        color: $alt;

        a:hover {
            color: $alt;
        }
    }

    .bp5-tab-indicator-wrapper .bp5-tab-indicator {
        background-color: $alt;
    }
}

.admin__navigation {
    border-bottom: 1px solid $divider;
}

.admin__content {
    flex-grow: 1;
}
