.filters {
    display: grid;
    grid-template-columns: 1fr 30px 2fr;
    margin-bottom: 20px;
}

.filter__label {
    padding-top: 6px;
    padding-right: 10px;

    &--any {
        color: $mutedText;
    }
}

.filter__button {
    background: none;
    border: none;
    align-self: start;
    line-height: 100%;
    &--multi {
        margin-top: 10px;
    }
}

.filter__value {
    font-weight: 400;
    padding-top: 6px;
    padding-left: 10px;

    &--any {
        color: $mutedText;
    }
}

.filter__input {
    margin-left: 10px;
}

.v2__form-multi-select-popover .bp5-menu{
    max-height: 200px;
    overflow: auto;
}
