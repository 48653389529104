.patient-list {
    margin: 20px;
    overflow: auto;

    &__table {
        width: 100%;
        border-collapse: collapse;

        thead {
            position: sticky;
            top: 0;
            background-color: white;
            font-size: 14px;
            font-weight: bold;
            color: $mutedText;
        }

        th,
        td {
            text-align: left;
            padding: 10px;
            border: 1px solid $color-border-main;
        }
    }

    &__header-flex {
        display: flex;
        align-items: center;
    }

    &__header-text {
        flex-grow: 2;
        &.ASC, &.DESC {
            color: $color-blue; 
        }
        &.ascending, &.descending {
            color: $color-blue;
        }
    }

    &__header-sort-icon {
        display: block;
        height: 16px;
        width: 16px;

        fill: grey;

        &--active {
            fill: blue;
        }
    }

    &__no-patients-row {
        td {
            text-align: center;
        }
    }

    &__header {
        user-select: none;

        &--sortable {
            cursor: pointer;
        }

        &--sorted {
            font-weight: bodler;
        }

        &--disabled {
            cursor: wait;
        }
    }

    &__job-cell {
        cursor: pointer;
    }

    &__job-cell-details {
        font-size: smaller;
    }

    &__not-set {
        color: darkgrey;
    }

    &__actions-cell {
        width: 1%;
        white-space: nowrap;
    }
}

//
.ASC {
    .icon-asc {
        fill: $color-blue; 
    }
    .icon-desc {
        display: none;
    }
}

.DESC {
    .icon-desc {
        fill: $color-blue; 
    }
    .icon-asc {
        display: none;
    }
}

.ascending {
    .icon-ascending{
        fill: $color-blue;
    }
    .icon-descending{
        display: none;
    }
}
.descending {
    .icon-descending{
        fill: $color-blue;
    }
    .icon-ascending{
        display: none;
    }
}
// dialog discharge
.ucr__dialog-discharge-buttons {
    margin-top: 25px;
}
.ucr__dialog-discharge-cancel {
    background-color: $color-grey;
}
.ucr__dialog-discharge-confirm {
    margin-left: 20px;
}
.ucr__patient-list-admission-status {
    width: 100%;
    padding: 5px;
    text-align: center;
    border-radius: $border-radius-sm;
    color: rgba($color-white, 0.8);
    font-size: 14px;
    &__admitted {
        background-color: $color-task-border-completed;
    }

    &__discharged {
        background-color: $color-task-border-available;
    }
}
.ucr__patient_list_highlight_dialog_name {
    color: $color-blue;
}
