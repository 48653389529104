.file-upload__input {
    background-color: $informationBackground;
    border: 1px solid $information;
    color: $information;
    padding: 10px 20px;
    border-radius: 2px;
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;

    &--uploading {
        cursor: not-allowed;
    }

    &--conflict {
        background-color: $warningBackground;
        border-color: $warning;
        color: $warning;
        cursor: default;
    }

    &--success {
        background-color: $successBackground;
        border-color: $success;
        color: $success;
        cursor: default;
    }
}

.file-upload__icon {
    margin-right: 10px;
}

.file-upload__error {
    margin-bottom: 20px;
}

.file-upload__button-row {
    margin-top: 10px;
}

.file-upload__button {
    margin-right: 5px;
}

.file-upload__table {
    width: 100%;

    .bp5-control {
        margin: 0;
    }
}

.file-upload__table-row {
    &--selected {
        background-color: $fadedBackground;
    }
}

.file-upload__error-list {
    margin: 0;
}

.multiple-file-upload {
    .list-of-uploads {
        display: flex;
        border: 1px solid $divider;
        margin-bottom: 10px;
        border-radius: 5px;
        flex-wrap: wrap;
        padding: 5px;
    }
    .uploaded-file {
        background-color: #2c609d;
        color: white;
        padding: 10px;
        font-family: 'Mulish', sans-serif;
        font-weight: 500;
        border-radius: 3px;
        margin: 2px;
    }
    &.view {
        .uploaded-file {
            cursor: pointer;
        }
    }
    .file-upload__delete-icon {
        padding-left: 5px;
        cursor: pointer;
    }

    label {
        padding: 4px;
        display: block;
        background: $lightGreyBackground;
        border-radius: 5px;
    }
    .file-upload__drop-area {
        color: #2c609d;
        background-color: $lightGreyBackground;
        text-align: center;
        padding: 20px;
        font-family: 'Mulish', sans-serif;
        font-weight: 700;
        cursor: pointer;
        border: 1px solid $divider;
    }
}
