.ucr-urgent-warning-button {
    border-radius: $border-radius-sm;

    &--alert {
        animation: ucr-urgent-warning-button__pulse 2s infinite;
    }
}

.ucr-urgent-warning-button__left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ucr-urgent-warning-button__right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/// Defines the offset to subtract from the height to prevent viewport overflow
$ucr-urgent-warning-button__dropdown-offset: 184px;

.ucr-urgent-warning-button__dropdown {
    position: absolute;
    z-index: 100;

    margin-top: 6px;
    background-color: white;

    border: solid red 2px;
    border-radius: $border-radius-sm;
    padding: 6px;

    max-height: calc(100% - #{$ucr-urgent-warning-button__dropdown-offset});
    overflow: auto;

    &--shown {
        display: inherit;
    }

    &--hidden {
        display: none;
    }
}

.ucr-urgent-warning-button__drop-down-button {
    width: 100%;
    height: 100%;
    display: block;
    text-align: left;

    + .ucr-urgent-warning-button__drop-down-button {
        margin-top: 3px;
    }
}

.ucr-urgent-warning-button__type {
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    width: 100px;
    text-align: right;
}

.ucr-urgent-warning-button__content {
    display: inline-block;
    padding-left: 5px;
    margin: 0;
    width: 305px;
}

.ucr-urgent-warning-button__dt {
    display: inline-block;
    width: 100px;
}

.ucr-urgent-warning-button__dd {
    display: inline-block;
    width: 200px;
    margin-left: 0;
}

.ucr-urgent-warning-container {
    margin-left: 10px;
    margin-top: 10px;
    border: 2px solid $color-red;
    border-radius: 5px;
    padding: 5px;
    background-color: $color-white;
}

.ucr-urgent-warning-base {
    fill: $color-text-main;
}

.ucr-urgent-warning-notify {
    fill: $color-red;
}

.ucr-urgent-warning-alert {
    animation: ucr-urgent-warning-button__bell 1s infinite;
}

@keyframes ucr-urgent-warning-button__pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(256, 0, 0, 0.7);
    }

    70% {
        box-shadow: 0 0 0 20px rgba(256, 0, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(256, 0, 0, 0);
    }
}

@keyframes ucr-urgent-warning-button__bell {
    0% {
        transform: rotateY(0deg) rotate(15deg);
    }

    50% {
        transform: rotateY(0deg) rotate(-15deg);
    }

    100% {
        transform: rotateY(0deg) rotate(15deg);
    }
}

