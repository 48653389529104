.callout {
    margin-bottom: 20px;
}

.callout__content {
    display: flex;
    align-items: center;
}

.callout__tag {
    margin-left: auto;
}
