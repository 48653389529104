.patients-table {
    width: 100%;

    .bp5-control {
        margin: 0;
    }
}

.patients-table__row {
    &--selected {
        background-color: $fadedBackground;
    }

    &--requires-attention,
    &--hcp-aborted {
        background-color: rgba($dangerBackground, 0.5);
    }
}

table.bp5-html-table.patients-table th {
    font-weight: normal;

    &:first-child {
        padding-left: 20px;
    }
}

table.bp5-html-table.patients-table td {
    color: $primary-darker;
    border-bottom: 1px solid $secondary;

    &:first-child {
        padding-left: 20px;
    }

    &:last-child {
        padding-right: 20px;
    }
}

table.bp5-html-table.patients-table tr:last-child td {
    border: none;
}

table.bp5-html-table.patients-table tr.patients-table__row--requires-attention td,
table.bp5-html-table.patients-table tr.patients-table__row--hcp-aborted td {
    color: $danger;
}
