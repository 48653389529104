.v2__form-group .react-tel-input {
    input[type='tel'].v2__form-phone-input {
        width: 100%;
        height: $input-height;

        @include font-input;

        background-color: $color-light-50;
        box-shadow: 0 0 0 1px $color-border-main-50;
        transition: box-shadow $transition-mouse-over;

        border: none;
        outline: none;
        border-radius: $border-radius-sm;

        &:hover {
            background-color: $color-light-50;
            box-shadow: 0 0 0 1px $color-border-main;
        }

        &:focus {
            box-shadow: 0 0 0 1px $color-blue;
        }
    }

    .flag-dropdown {
        border: none;
    }
}

.v2__form-group--error .react-tel-input input[type='tel'].v2__form-phone-input {
    box-shadow: 0 0 0 1px $color-red;
}
