.h1 {
    border-bottom: 1px solid rgba($primary-darker, 0.25);
    color: $primary-darker;
    font-size: 28px;
    font-weight: 300;
    margin: 0 0 20px;
    padding-bottom: 20px;
    line-height: 40px;
}

.h2 {
    border-bottom: 1px solid rgba($alt, 0.25);
    color: $alt;
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    margin: 0 0 20px;
}

.h3 {
    border-bottom: 1px solid rgba($alt, 0.25);
    color: $alt;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin: 0 0 15px;
    padding-bottom: 5px;
}

.h4 {
    color: $tertiary;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 0 0 10px;
}

.highlight-warning {
    color: $warning-darker;
}

.disabled{
    opacity: 0.5;
    pointer-events: none;
}
