.info-group {
  display: grid;
  grid-template-columns: 100px auto;
  grid-gap: 10px;
  border-bottom: 1px solid $divider;

  &__title {
    margin: auto;
    font-weight: bold;
    text-align: center;
  }

  .info {
    margin-bottom: 0;
  }
}

.info {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 0 0 20px;
  &.no-margin {
    margin: 0;
  }
}

.info__weekDay {
  width: 120px;
}

.info__heading {
  border-bottom: 1px solid $secondary;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.info__title {
  border-bottom: 1px solid $mutedBackground;
  font-weight: bold;
  padding: 10px;
  display: flex;

  .required-asterisk {
    color: $danger;
    margin-left: 2px;
  }

  &--secondary {
    color: #A60705;
    margin-left: 50px;
  }

  &--danger {
    color: $danger-darker;
  }

  &--warning {
    color: $warning-darker;
  }
}

.info__title-icon {
  margin-right: 7px;
  color: $warning;
}

.info__definition {
  border-bottom: 1px solid $mutedBackground;
  padding: 10px 0 10px 20px;
  margin: 0;

  &-item {
    &:before {
      content: ', ';
    }

    &:first-child:before {
      content: '';
    }
  }

  &--notes {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
  }

  &--danger {
    color: $danger-darker;
  }

  &--warning {
    color: $warning-darker;
  }

  &--inline {
    display: flex;

    > .bp5-form-group.bp5-inline {
      padding: 0;
    }
  }

  &--inline-between {
    display: flex;
    justify-content: space-between;

    .bp5-form-group {
      width: 100%;
    }
  }

  &--fullWidth {
    .bp5-form-content .bp5-numeric-input .bp5-input-group.bp5-large {
      width: 100%;
    }
  }

  &--week {
    display: flex;
    flex-wrap: wrap;

    > .bp5-form-group.bp5-inline {
      align-items: center;
      padding: 0;
      width: 14.2%;
    }
  }

  .bp5-form-group {
    margin: 5px 0 10px;

    &:only-of-type {
      margin: 0;
    }
  }

  > .bp5-control {
    display: inline-flex;
    margin: 0 20px 5px 0;
    align-items: center;
  }

  .bp5-button {
    margin-left: 10px;
  }

  .bp5-button:first-child {
    margin-left: 0;
  }
}

.info__title:last-of-type,
.info__definition:last-of-type {
  border-bottom: none;
}

.info__block {
  display: block;
}

.info__tag,
.info__icon {
  margin-right: 5px;
}

.info__change-arrow {
  margin: 0 5px;
  color: $success-darker;
}

.collapsed-details {
  margin-bottom: 20px;
}

.info {
  &.departments_suggest, &.costCentres_suggest {
    margin-bottom: 0;
    border-bottom: 1px solid $mutedBackground;
  }
}

.info-notification {
  color: var(--text-blue);
  margin-top: 5px;
  margin-bottom: 0;
  &__icon {
    display: inline-block;
    font-size: calc(var(--fz-sm) * 1px);
    margin-top: 5px;
    &:before {
      content: 'i';
      padding: 0 5.5px;
      border-radius: 50%;
      border: 1px solid currentColor;
      margin-right: 5px;
    }
  }
}

.collapse-info-fields {
  .bp5-collapse-body {
    margin-top: 10px;
    transform: none!important;
  }
}