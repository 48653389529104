.info-panel-textarea__container {
    display: grid;
    textarea {
        width: 100%;
        height: 120px;

        @include font-input;
        line-height: $line-height-h4;

        background-color: $color-light-50;
        box-shadow: 0 0 0 1px $color-border-main-50;
        transition: box-shadow $transition-mouse-over;

        border: none;
        outline: none;
        border-radius: $border-radius-sm;
        resize: none;

        &:hover {
            background-color: $color-light-50;
            box-shadow: 0 0 0 1px $color-border-main;
        }
        &:focus {
            box-shadow: 0 0 0 1px $color-blue;
        }
    }
}

.v2-info__definition.info--item__hover {
    &:hover {
        cursor: pointer;
        background-color: $color-light-50;
        box-shadow: 0 0 0 1px $color-border-main;
    }
}

.v2__accordion-editIcon-infopanel {
    height: 16px;
    width: 16px;
}

.info--item-label__aligned {
    display: flex;
    align-items: center;
    gap: 6px;
    .v2__accordion-editIcon {
        width: 16px;
        height: 16px;
    }
}

.info-panel-textarea {
    &__loading {
        margin-top: 12px;
    }
    &__buttons {
        gap: 12px;
        justify-content: flex-end;
        display: flex;
        margin-top: 12px;
    }
}

.info--item-label__aligned-grid {
    display: grid;
    grid-template-columns: auto 20px;
    gap: 4px;
}
