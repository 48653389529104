@media print {
    .modal {
        height: 100%;
        width: 100%;
        display: inline-block;
        overflow: visible;
    }
    .modal::-webkit-scrollbar {
        display: none;
    }
}

.modal {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 0;

    .bp5-dialog-header {
        padding-left: 15px;
    }
}

.modal--shadow {
    background-color: rgba(0, 0, 0, 0.25);
    padding: 10px;
}

.modal__wrapper {
    background-color: white;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.modal__title {
    border-bottom: 1px solid $secondary;
    padding: 10px 20px;
    display: flex;
    align-items: center;

    @media (min-width: 992px) {
        padding: 15px 30px;
    }
}

.modal__heading {
    margin: 0;
    border: none;
    display: flex;
    align-items: center;
    color: $text;
}

.modal__heading-text {
    margin-right: 10px;
}

.modal__header-btn {
    margin-left: auto;
}

.modal__sub-heading {
    border-color: rgba($alt, 0.25);
    color: $alt;
    display: flex;
    align-items: center;
}

.bp5-button.modal__sub-heading-btn {
    margin-left: auto;

    &:hover {
        background-color: rgba($alt, 0.15);
    }

    .bp5-icon {
        color: $alt;
    }
}

.modal__content {
    position: relative;
    margin: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media (min-width: 992px) {
        margin: 30px;
    }

    .bp5-tabs,
    .bp5-tab-panel[aria-hidden='false'] {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

.modal__footer {
    border-top: 1px solid $divider;
    padding: 15px 60px 15px 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: auto;

    .bp5-button {
        margin-left: 10px;
    }
}

.modal__footer-text {
    color: $mutedText;
    margin-right: auto;
}

.modal__footer-icon {
    margin-right: 5px;
}

.modal__lightbox {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.radio-label-without-margin {
    margin-bottom: 0;
}

.modal__tabs,
.modal__tabs .bp5-tab-panel[aria-hidden='false'],
.modal__form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.modal__form .modal__footer {
    padding-left: 0;
    padding-right: 0;
}

.modal__overflow-initial {
    overflow: initial !important;
}

.modal__footer-buttons {
    display: flex;
    justify-content: flex-end;
}

.modal__container {
    margin: 15px;

    .modal__paragraph {
        margin-top: 30px;
    }
}

.modal__label--itinerary {
    font-size: 14px;
    margin-left: 10px;
}

.modal__dialog--with-existing-route {
    width: 60%;
}