.ucr__header {
    background: transparent linear-gradient(90deg, $color-blue-dark 0%, $color-pink 100%);
    border-bottom: none;

    .nav__item-available,
    .nav__item-total {
        color: $color-white;
    }

    .bp5-tab-indicator {
        background-color: $color-white;
    }

    .bp5-tab {
        color: $color-white;

        &[aria-selected='false'] {
            opacity: 0.75;
        }

        &:hover,
        a:hover {
            color: $color-white;
            opacity: 1;
        }
    }
}
