.v2__form-group .v2__form-tag-input {
    width: 100%;
    height: $input-height;

    @include font-input;

    background-color: $color-light-50;
    box-shadow: 0 0 0 1px $color-border-main-50;
    transition: box-shadow $transition-mouse-over;

    border: none;
    outline: none;
    border-radius: $border-radius-sm;

    &:hover {
        background-color: $color-light-50;
        box-shadow: 0 0 0 1px $color-border-main;
    }

    &:focus {
        box-shadow: 0 0 0 1px $color-blue;
    }

    .bp5-input-ghost {
        font-weight: 600;

        &::placeholder {
            color: $color-grey;
        }
    }
}

.v2__form-group .v2__form-tag-input.bp5-active {
    box-shadow: 0 0 0 1px #3281ac;
}

.v2__form-group--error .v2__form-tag-input {
    box-shadow: 0 0 0 1px $color-red;
}
