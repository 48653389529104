.s1-message {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
}

.s1-message__column {
    overflow-x: hidden;
}

.s1-message__column-heading {
    @include font-h4;
    margin-top: 0;
}

.s1-message__code {
    padding: 15px;
    overflow-x: auto;
    background-color: $color-light-50;
    border-radius: $border-radius-sm;
    border: 1px solid $color-border-main-50;
}
