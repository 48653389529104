.alert {
    max-width: 400px;
    padding: 20px;
}
.alert__body {
    display: flex;
}
.alert__contents {
    word-break: break-word;
}
.alert__footer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
}

.alert__btn {
    margin-left: 10px;
}

.confirmation-alert {
    .bp5-alert-body {
        align-items: center;
    }
    &.confirmation-alert--not-confirmed {
        button.bp5-intent-danger {
            @extend .disabled;
        }
    }
}

.bp5-dialog {
    &.bold-description {
        p {
            font-weight: bold;
        }
    }
    &.v2__alert-dialog {
        max-width: 500px;
        .bp5-alert-body{
            .info{
                align-items: center;
                margin-bottom: 30px;
                .info__title{
                    padding: 10px 0;
                    font-weight: 500;
                }
                .info__definition{
                    .form-error-wrapper{
                        position: absolute;
                        max-width: 300px;
                    }
                }
            }
        }
        .alert__title {
            margin-top: 0;
            .alert__heading-text{
                font-size: 20px;
                margin-bottom: 10px;
                font-weight: 600;
            }
        }
        .bp5-popover-target{
            display: block;
            .bp5-input-group {
                .bp5-input{
                    height: 40px;
                    line-height: 40px;
                    font-size: 16px;
                }
            }
        }
        /* looks like bluprintjs 5 does not wrap DateInput in a bp5-popover-target the .bp5-popover-target .bp5-input-group above may be redundant
        .bp5-popover-target is sill used */
        .bp5-input-group {
            .bp5-input{
                height: 40px;
                line-height: 40px;
                font-size: 16px;
            }
        }
    }
}





