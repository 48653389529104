.historic-jobs-list__item {
    margin-bottom: 20px;
    position: relative;
    padding-top: 25px;

    &--completed {
        border: 1px solid $success;
    }
}

.historic-jobs-list__tag {
    position: absolute;
    top: -10px;
    left: 10px;
}

.historic-jobs-list__ok {
    color: $success;
}

.historic-jobs-list__warning {
    color: $warning;
}

.historic-jobs-list__danger {
    color: $danger;
}
