.absence {
    &-inner-header {
        border-bottom: 1px solid $divider;
        display: flex;
        justify-content: space-between;
        color: $personTitleColor;
        font-family: "Mulish",sans-serif;
        position: relative;
        &.full-border {
            &:after {
                content: '';
                position: absolute;
                bottom: -1px;
                left: -30px;
                width: calc(100% + 60px);
                height: 1px;
                background: $divider;
            }
        }
        &.inner {
            border-top: none;
        }
        &.create-form,
        &.edit-form {
            margin-bottom: 15px;
        }
        button {
            span {
                font-family: "Mulish",sans-serif;
                color: $personTitleColor;
                font-weight: 500;
            }
            &:hover {
                background: none!important;
            }
        }
    }
    .primary-color {
        color: $information-darker;
    }

    .go-back-link {
        color: $information;
        padding-left: 0;
        font-family: "Mulish",sans-serif;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: -5px;
        .text {
            text-decoration: underline;
        }
        &:hover {
            background: none;
            color: $information;
        }
    }
}

.absence-view {
    font-family: "Mulish",sans-serif;
    font-weight: 400;
    color: $personTitleColor;
    .view-section-title {
        margin-bottom: 5px;
    }
    .view-section-description {
        margin-bottom: 10px;
    }
}

.absence-view-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    .view-section {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        &:last-child {
            .view-group {
                grid-row-start: 2;
            }
        }
    }
}

.uploaded-document-link {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    &:hover {
        color: white;
    }
}

.form-section-notes {
    grid-column-start: 1;
}

.notes-description {
    border: 1px solid $divider;
    border-radius: 5px;
    padding: 5px;
    min-height: 100px;
}

.absence__footer {
    .no-intent {
        background: $noIntentButtonColor;
        color: white;
        &:hover {
            background: $noIntentHoverButtonColor;
        }
    }
    button {
        margin-right: 10px;
        box-shadow: none;
    }
}

.absence__form {
    margin-top: 15px;
    color: $personTitleColor;
    .input-with-icon {
        .bp5-form-content {
            position: relative;
        }
        .icon-near-input {
            position: absolute;
            left: 10px;
            top: 12px;
        }
        &.date {
            input {
                padding-left: 35px;
                background: transparent;
            }
        }
        &.time {
            .bp5-timepicker-input-row {
                padding-left: 35px;
                background: transparent;
            }
        }
        
    }
    .time {
        .bp5-timepicker-input-row {
            width: 100%;
        }
    }
    textarea {
        resize: none;
    }
    .no-label {
        margin-top: 22px;
    }
    .disabled-async-select {
        [class*="control"] {
            background: rgba(206, 217, 224, 0.5);
        }
        input {
            color: rgba(92, 112, 128, 0.6)!important;
            visibility: visible;
            font-size: 16px!important;
        }
    }
}

.absence-record-container {
    &.inside-profile {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .common-layout__modal {
        height: 100%;
    }
    .modal.modal--shadow {
        background: #bfbfbf;
    }
}
