.ucr__section {
    font-family: $font-family-main;
    font-weight: 400;

    color: $color-text-main;
    background-color: $color-light;

    position: relative;
    display: flex;
    overflow: hidden;
}

.ucr__section-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
}
