// FONT FILES

$font-family-names: 'Mulish';
$font-weight-names: 'Light', 'Regular', 'Medium', 'SemiBold', 'Bold';
$font-weight-types: 300, 400, 500, 600, 700;
$font-style-names: '', 'Italic';
$font-style-types: normal, italic;

@for $f from 1 through length($font-family-names) {
    $family-name: nth($font-family-names, $f);

    @for $w from 1 through length($font-weight-names) {
        $weight-name: nth($font-weight-names, $w);
        $weight-type: nth($font-weight-types, $w);

        @for $s from 1 through length($font-style-names) {
            $style-name: nth($font-style-names, $s);
            $style-type: nth($font-style-types, $s);

            $file: '#{$family-name}-#{$weight-name}#{$style-name}';
            $path: './fonts/#{$family-name}/';

            @font-face {
                font-family: $family-name;
                src: url($path + $file + '.woff2') format('woff2');
                font-weight: $weight-type;
                font-style: $style-type;
                font-display: block;
            }
        }
    }
}
