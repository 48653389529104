@mixin font-h2 {
    font-family: $font-family-main;
    font-size: $font-size-h2;
    line-height: $line-height-h2;
    font-weight: 600;
}

@mixin font-h3 {
    font-family: $font-family-main;
    font-size: $font-size-h3;
    line-height: $line-height-h3;
    font-weight: 600;
}

@mixin font-h4 {
    font-family: $font-family-main;
    font-size: $font-size-h4;
    line-height: $line-height-h4;
    font-weight: 600;
}

@mixin font-h5 {
    font-family: $font-family-main;
    font-size: $font-size-h5;
    line-height: $line-height-h5;
    font-weight: 600;
}

@mixin font-h6 {
    font-family: $font-family-main;
    font-size: $font-size-h6;
    line-height: $line-height-h6;
    font-weight: 300;
}

@mixin font-p {
    font-family: $font-family-main;
    font-size: $font-size-p;
    line-height: $line-height-p;
    font-weight: 400;
}

@mixin font-input {
    @include font-h4();
    line-height: $input-height;
    color: $color-text-dark;
}
