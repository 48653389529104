.v2__form-group {
    margin-bottom: 0;
    grid-column-start: 1;
    grid-column-end: initial;

    &--pos-1-1 {
        grid-column: 1 / span 12;
    }
    &--pos-1-2 {
        grid-column: 1 / span 6;
    }
    &--pos-2-2 {
        grid-column: 7 / span 6;
    }
    &--pos-1-3 {
        grid-column: 1 / span 4;
    }
    &--pos-2-3 {
        grid-column: 5 / span 4;
    }
    &--pos-3-3 {
        grid-column: 9 / span 4;
    }

    &--pos-1-4 {
        grid-column: 1 / span 3;
    }
    &--pos-2-4 {
        grid-column: 4 / span 3;
    }
    &--pos-3-4 {
        grid-column: 7 / span 3;
    }
    &--pos-4-4 {
        grid-column: 10 / span 3;
    }

    &--align-right {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
    }

    &--flex-wrap {
        display: flex;
        flex-wrap: wrap;
    }

    &--flex-part {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50%;
        padding-bottom: 12px;

        & > .v2__form-group .bp5-form-helper-text {
            position: absolute;
        }
    }

    &--notes {
        white-space: pre-wrap;
        overflow-wrap: anywhere;
    }
}

.v2__form-group > label.bp5-label {
    @include font-h4;
    color: $color-text-main;
}

.v2__form-group > label.bp5-label .bp5-text-muted {
    color: $color-red;
}

.v2__form-group .bp5-form-helper-text {
    @include font-p;
    color: $color-text-main;
}

.v2__form-group.v2__form-group--error .bp5-form-helper-text {
    color: $color-red;
}
