.visit-details-warnings {
    &__outer {
        display: flex;
        align-items: center;
        margin-right: 5px;

        + .visit-details-warnings__outer {
            margin-top: 8px;
        }
    }

    &__button {
        display: flex;
        margin-right: 5px;
    }

    &__button-inner {
        width: 168px;
    }

    &__text {
        display: flex;
        vertical-align: middle;
    }
    &__view-details {
        display: flex;
        vertical-align: middle;
        text-decoration: underline;
        color: $information;
        margin-right: 10px;
        min-width: 80px;
        cursor: pointer;
        border: none;
        background: none;
        text-decoration-line: underline;
        padding: 0px;
    }
    &__originalalert-text {
        margin-top: 8px;
    }
}

.visit-details-warnings__view-details:focus { outline: none; }
