.common-layout {
  background-color: $primary-darker;
  display: grid;
  grid-template-columns: minmax(300px, 0.5fr) 3fr;
  overflow-y: auto;
  column-gap: 1px;
  grid-template-areas: 'aside main';
}

.common-layout__tools {
  grid-area: aside;
  overflow-y: auto;
  background-color: $fadedBackground;
  display: flex;
  flex-direction: column;
}

.common-layout__main {
  background-color: white;
  overflow: auto;
  grid-area: main;
  padding: 20px;
  flex: 1 1 auto;
}

.common-layout__modal {
  position: relative;
  grid-area: main;
  overflow-y: auto;
  z-index: 10;
}
