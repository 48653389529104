.v2__form-group .v2__form-textarea-input {
    width: 100%;
    height: 100px;

    @include font-input;
    line-height: $line-height-h4;

    background-color: $color-light-50;
    box-shadow: 0 0 0 1px $color-border-main-50;
    transition: box-shadow $transition-mouse-over;

    border: none;
    outline: none;
    border-radius: $border-radius-sm;
    resize: none;

    &:hover {
        background-color: $color-light-50;
        box-shadow: 0 0 0 1px $color-border-main;
    }
    &:focus {
        box-shadow: 0 0 0 1px $color-blue;
    }
}
