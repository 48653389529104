.links {
    margin-bottom: 20px;
    width: 100%;
}

.links__url {
    color: $historic;
    font-style: italic;
}

table.links td {
    vertical-align: middle;

    &:last-child {
        text-align: right;
    }
}

.links__form {
    display: flex;
}

.links__input {
    flex-grow: 1;
    margin: 0 10px 0 0;
}
