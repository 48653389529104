.minimal-button {
    margin: 0 0 0 5px;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    color: $information;

    &:hover {
        text-decoration: underline;
    }
}
