.v2__overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, 0.5);
    position: absolute;
    z-index: 50;
    border: none;

    &--show {
        transition: visibility $duration-appearance step-start,
            opacity $duration-appearance $function-appearance;
        visibility: visible;
        opacity: 1;
    }

    &--hide {
        transition: visibility $duration-appearance step-end,
            opacity $duration-appearance $function-appearance;
        visibility: hidden;
        opacity: 0;
    }
}

.v2__overlay:focus { outline: none; }
