.v2__form-group .v2__form-radio-input {
    user-select: none;
    align-items: center;
    display: inline-flex;

    &--left {
        position: absolute;
        left: 45px;
    }
}

.v2__form-group .v2__form-radio-input input ~ .bp5-control-indicator {
    margin-top: 0;
    width: 15px;
    height: 15px;

    font-size: 16px;
    line-height: 16px;

    color: $color-text-main;
    background-color: #fff;
    transition: background-color $transition-mouse-over, box-shadow $transition-mouse-over;

    border: none;
    outline: none;
    border-radius: 50%;

    align-items: center;
    justify-content: center;
    display: inline-flex;
}

.v2__form-group .v2__form-radio-input:hover {
    .bp5-control-indicator {
        background-color: #fff;
    }
}

.v2__form-group .bp5-control.bp5-radio input:checked ~ .bp5-control-indicator::before {
    background-image: radial-gradient($color-blue, $color-blue 28%, transparent 32%);
}

.v2__form-group .bp5-control.bp5-radio input:checked ~ .bp5-control-indicator:hover {
    background-color: #fff;
}

.bp5-control.bp5-radio:hover input:checked ~ .bp5-control-indicator {
    background-color: #fff;
}
