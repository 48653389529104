// See https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#styling

[data-amplify-authenticator] {
    --amplify-components-authenticator-router-border-width: 0;
    --amplify-components-authenticator-router-box-shadow: none;

    --amplify-components-text-color: #{$color-text-main};
    --amplify-components-text-error-color: #{$color-red};

    --amplify-components-heading-color: #{$color-text-main};
    --amplify-components-heading-2-font-size: #{$font-size-h2};
    --amplify-components-heading-2-font-weight: 600;
    --amplify-components-heading-2-line-height: #{$line-height-h2};
    --amplify-components-heading-3-font-size: #{$font-size-h2};
    --amplify-components-heading-3-font-weight: 600;
    --amplify-components-heading-3-line-height: #{$line-height-h2};

    font-family: $font-family-main;

    a,
    a:hover {
        color: $color-blue-dark;
    }

    .amplify-heading--2 {
        margin-bottom: -16px;
    }

    .amplify-button {
        --amplify-components-button-font-size: #{$font-size-h4};
        --amplify-components-button-font-weight: 500;
        --amplify-components-button-outlined-error-border-color: #{$color-red};

        &--primary {
            --amplify-internal-button-background-color: #{$color-blue};

            &:hover {
                --amplify-internal-button-background-color: #{mix($color-white, $color-blue, 10%)};
            }
        }

        &--link {
            --amplify-internal-button-color: #{$color-text-main};

            &:hover {
                --amplify-internal-button-background-color: #{mix(
                        $color-white,
                        $color-border-main,
                        75%
                    )};
            }
        }
    }

    .amplify-label {
        --amplify-components-field-label-color: #{$color-text-main};

        font-size: $font-size-h4;
        font-weight: 600;
        line-height: $line-height-h4;
    }

    .amplify-input {
        --amplify-components-fieldcontrol-font-size: #{$font-size-h4};
        --amplify-components-fieldcontrol-line-height: #{$input-height};
        --amplify-components-fieldcontrol-color: #{$color-text-dark};
        --amplify-components-fieldcontrol-error-border-color: #{$color-red};

        font-weight: 600;
        height: $input-height;
        background-color: $color-light-50 !important;
        box-shadow: 0 0 0 1px $color-border-main-50 !important;
        transition: box-shadow $transition-mouse-over !important;
        border: none !important;
        outline: none !important;
        border-radius: $border-radius-sm !important;

        &--error {
            box-shadow: 0 0 0 1px $color-red !important;
        }

        &:hover {
            background-color: $color-light-50 !important;
            box-shadow: 0 0 0 1px $color-border-main !important;
        }

        &:focus {
            box-shadow: 0 0 0 1px $color-blue !important;
        }

        &::placeholder {
            color: $color-grey !important;
        }

        &[name='password'] {
            border-end-end-radius: 0 !important;
            border-start-end-radius: 0 !important;
        }
    }

    .amplify-field__show-password {
        background-color: $color-light-50 !important;
        box-shadow: 0 0 0 1px $color-border-main-50 !important;
        border: none !important;
        outline: none !important;
    }
}
