.markdown {
    padding: 10px 30px;

    h2 {
        font-size: 20px;
        margin: 20px 0 10px;
        border-bottom: 1px solid $divider;
        padding-bottom: 10px;
        font-weight: normal;
    }

    h3 {
        font-size: 16px;
    }
}
