.vaccinations {
    background-color: $divider;
    display: grid;
    grid-template-columns: minmax(300px, 1fr) 3fr;
    grid-template-rows: auto 1fr;
    overflow-y: auto;
    column-gap: 1px;
    row-gap: 1px;
    grid-template-areas:
        'tabs navigation'
        'tools main';
}

.vaccinations__tabs {
    background-color: $divider;
    column-gap: 1px;
    grid-area: tabs;
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
}

.vaccinations__tabs--experimental {
    grid-template-columns: 1fr 1fr 1fr;
}

.vaccinations__tab {
    background-color: white;
    color: $primary-darker;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-size: 16px;

    &:hover {
        text-decoration: none;
        background-color: $primaryBackground;
    }

    &--active,
    &--active:hover {
        background-color: $primary-darker;
        color: white;
    }
}

.vaccinations__tab-icon {
    margin-right: 10px;
}

.vaccinations__navigation {
    background-color: white;
    color: $mutedText;
    grid-area: navigation;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
}

.vaccinations__navigation-button {
    margin-right: 10px;
}

.vaccinations__navigation-info {
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px) {
        flex-direction: row;
    }
}

@media (min-width: 1200px) {
    .vaccinations__selected-count {
        margin-left: 20px
    }
}

.vaccinations__tools {
    grid-area: tools;
    overflow-y: auto;
    background-color: $fadedBackground;
    padding: 14px 20px;
    display: flex;
    flex-direction: column;
}

.vaccinations__tools-buttons {
    margin-top: auto;
}

.vaccinations__tools-button {
    margin-top: 10px;
    width: 100%;
}

.vaccinations__main {
    background-color: white;
    overflow: auto;
    grid-area: main;
}

.vaccinations__main--full {
    background-color: white;
    overflow: auto;
    grid-row-start: -3;
    grid-row-end: -1;
    grid-column-start: 2;
    grid-column-end: 3;
}

.vaccinations__modal {
    position: relative;
    grid-row-start: -3;
    grid-row-end: -1;
    grid-column-start: 2;
    grid-column-end: 3;
    overflow-x: hidden;
    z-index: 10;
}

.vaccinations__loading {
    flex-grow: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vaccinations__instruction {
    display: flex;

    &--removed {
        text-decoration: line-through;
    }
}

.vaccinations__instruction-tag {
    padding-left: 10px;
    margin-left: auto;
    display: flex;
    align-items: center;
}

.vaccinations__instruction-warning {
    margin-left: 5px;
}

.vaccinations__instruction-tag-new {
    margin-right: 5px;
}

.vaccinations__form {
    height: 100%;
}

.vaccinations__print-only {
    display: none;
}

@media print {
    .vaccinations__print-only {
        display: block;
        margin-top: 10px;
    }
}

.accordion-patient-details {
    grid-column-start: 1;
    grid-column-end: 3;
    .v2__accordion-summary-name {   
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        margin-left: 10px;
    }
    .v2__accordion-content{
        display: grid;
        grid-template-columns: 1fr 2fr;
        border: none;
        background: #f6f9f9;
        select, input {
            width: calc(100% - 10px);
        }
        .bp5-html-select .bp5-icon{
            right: 17px;
        }
    }
    &:hover {
        cursor: pointer;
    }
}
