.ucr__side {
    flex: 0 0 auto;
    position: relative;
    z-index: 8;

    transition: width $transition-appearance;

    &--small {
        width: $side-small;
    }
    &--large {
        width: $side-large;
    }
    &--panel.ucr__side--small {
        width: $side-small + $side-panel;
    }
    &--panel.ucr__side--large {
        width: $side-large + $side-panel;
    }
}

.ucr__side-topbar {
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: $color-white;
    position: absolute;
    z-index: 1;
}

.ucr__side-wrapper {
    width: 100%;
    height: 100%;
    background-color: $color-white;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    z-index: 3;

    &--small {
        width: $side-small;
    }
    &--large {
        width: $side-large;
    }

    &--left {
        float: left;
        border-right: 1px solid $color-border-main;
    }

    &--right {
        float: right;
        border-left: 1px solid $color-border-main;
    }
}

.ucr__side-arrow {
    top: 50%;
    left: 0;
    width: 100%;
    height: 60px;
    transition: fill $duration-mouse-over $function-mouse-over;
    align-items: center;
    justify-content: center;
    position: absolute;
    display: flex;
    cursor: pointer;
    border: none;
    background: none;

    &--forward {
        transform: translateY(-50%) scaleX(1);
    }
    &--reverse {
        transform: translateY(-50%) scaleX(-1);
    }

    &--default {
        fill: $color-border-main;
    }
    &--hovered {
        fill: $color-grey;
    }

    &--disabled {
        pointer-events: none;
        cursor: default;
    }
}
.ucr__side-arrow > svg {
    width: 24px;
    height: 24px;
}

.ucr__side-arrow:focus { outline: none; }

.ucr__side-button {
    position: relative;
    padding: 20px 15px;
    width: 100%;
    height: 60px;
    transition: color $duration-mouse-over $function-mouse-over,
        background-color $duration-mouse-over $function-mouse-over,
        fill $duration-mouse-over $function-mouse-over;
    align-items: center;
    display: flex;
    user-select: none;
    cursor: pointer;
    border: none;
    background: none;

    &--top {
        border-bottom: 1px solid $color-light;
    }
    &--bottom {
        border-top: 1px solid $color-light;
    }

    &--small {
        justify-content: center;
    }
    &--large {
        justify-content: flex-start;
    }

    &--default {
        fill: $color-grey;
    }
    &--hovered {
        color: $color-text-main;
        background-color: $color-light;
        fill: $color-text-main;
    }
    &--active {
        color: $color-text-main;
        background-color: $color-light;
        fill: $color-pink;
        pointer-events: none;
        cursor: default;
    }
}

.ucr__side-button:focus { outline: none; }

.ucr__side-button-name {
    @include font-p;
    margin-left: 10px;
    display: block;
}

.ucr__side-button-icon,
.ucr__side-button-icon-full-size {
    display: flex;
    position: relative;
}
.ucr__side-button-icon > svg {
    width: 24px;
    height: 24px;
}

.ucr__side-button-icon-full-size > svg {
    width: 100%;
    height: 100%;
}

.ucr__side-button-text {
    @include font-p;
    color: $color-blue;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    text-decoration: underline;
}

.ucr__side-button-badge {
    position: absolute;
    bottom: 0;
    right: 3px;
    background-color: $color-blue;

    &--red, &--red-light {
        background-color: #900c0c;
    }
}

.ucr__side-button-action {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $color-light;
    border: none;
    background: none;

    &--active {
        color: $color-text-main;
        background-color: $color-light;
    }
    &--hovered {
        color: $color-text-main;
        background-color: $color-light;
    }
}

.ucr__side-button-action:focus { outline: none; }

// UCR Side Button Tooltip
// Verbosity due to fighting specificity from other styles in application
.bp5-portal .ucr__side-button-tooltip-bp-override .bp5-popover2-content.bp5-popover2-content {
    @include font-p;

    background-color: $guide-blue-grey-darker;
    border-radius: 5px;
    padding: 5px 6px;
}
