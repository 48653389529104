.v2__date-prev-next {
    align-items: center;
    display: flex;
}

.v2__date-prev-next-fields {
    position: relative;
    .bp5-input {
        min-width: 200px;

        font-size: $font-size-p;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        color: $color-text-date;

        position: relative;
        display: block;
        cursor: pointer;
        /* VSU-2303 blueprint-js v5 change as we no longer have an overlay label covering the date input.*/
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.v2__date-prev-next-label {
    min-width: 200px;

    font-size: $font-size-p;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: $color-text-date;

    position: relative;
    display: block;
    cursor: pointer;
}

.v2__date-prev-next-today {
    margin-right: 10px;
}

.v2__date-prev-next-popover {
    width: 100%;
    position: absolute;
    opacity: 0;
}

.v2__date-prev-next-popover .bp5-popover-target {
    width: 100%;
}

