.create-routes {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.create-routes__patients-selected {
    margin-bottom: 20px;
}

.create-routes__patients-selected-summary {
    display: flex;
    align-items: center;
}

.create-routes__patients-selected-button {
    margin-left: 5px;
}

.create-routes__select-date-time {
    margin-bottom: 20px;
}

.create-routes__date-time-controls {
    display: flex;
    align-items: center;

    .bp5-form-group {
        margin-right: 10px;
    }
}

.create-routes__please-wait {
    margin-top: 30px;
    color: $mutedText;
}

.create-routes__summaries {
    display: flex;
    margin: 5px 0;
    padding: 15px 1px;
    overflow-x: auto;
}

.create-routes__summary {
    margin-right: 20px;
    border-bottom-style: solid;
    border-bottom-width: 5px;
    min-width: 250px;
    position: relative;

    p:last-child {
        margin-right: 0;
    }
}

.create-routes__summary--snug {
    margin-bottom: 10px;
    margin-right: 0;

    p {
        margin-bottom: 5px;
    }
}

.create-routes__summary-btn {
    margin-top: 5px;
    margin-right: 5px;
}

.create-routes__summary-tag {
    position: absolute;
    top: 5px;
    right: 5px;
}

.create-routes__callout {
    margin-bottom: 20px;
}

table.bp5-html-table td.create-routes__sms {
    &--sent {
        color: $success;
    }

    &--not-sent {
        color: $danger;
    }
}

.bp5-form-content .bp5-numeric-input.create-routes__numeric-input .bp5-input-group.bp5-large {
    width: 60px;
}
