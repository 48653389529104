.loader-wrapper {
    z-index: 15;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;

    &--fullscreen {
        position: absolute;
    }
}
