.v2__form-group .v2__form-select-input {
    width: 100%;
}

.v2__form-group .v2__form-select-input select {
    padding-right: 30px;
    height: $input-height;

    @include font-input;

    background-color: $color-light-50;
    box-shadow: 0 0 0 1px $color-border-main-50;
    transition: box-shadow $transition-mouse-over;

    border: none;
    outline: none;
    border-radius: $border-radius-sm;

    &:hover {
        background-color: $color-light-50;
        box-shadow: 0 0 0 1px $color-border-main;
    }
    &:focus {
        box-shadow: 0 0 0 1px $color-blue;
    }
}

.v2__form-group .v2__form-select-input .bp5-icon {
    top: 12px;
}

.v2__form-group--error .v2__form-select-input select {
    box-shadow: 0 0 0 1px $color-red;
}

.high-z-index {
    z-index: 100;
}
