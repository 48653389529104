.v2__button {
    border: none;
    outline: none;
    transition: background-color $duration-mouse-over $function-mouse-over;
    user-select: none;
    cursor: pointer;
    font-family: $font-family-main;
    align-items: center;
    display: inline-flex;

    &.v2__button--white {
        border-width: 1px;
        border-style: solid;
    }

    &--small {
        padding: 8px;
        border-radius: $border-radius-sm;

        &.v2__button--white {
            padding: 7px;
        }
    }

    &--medium {
        padding: 10px 15px;
        border-radius: $border-radius-sm;
        height: 40px;

        &.v2__button--white {
            padding: 9px 14px;
        }
    }

    &--vertical {
        padding: 15px 20px 20px;
        border-radius: $border-radius-sm;
        flex-direction: column;

        &.v2__button--white {
            padding: 14px 19px 19px;
        }
    }

    &--full-width {
        width: 100%;
    }

    &--white {
        color: $color-text-main;
        fill: $color-text-main;
        background-color: $color-white;
        border-color: $color-border-main;

        &:hover {
            background-color: mix($color-white, $color-border-main, 75%);
        }
    }

    &--blue {
        color: $color-white;
        fill: $color-white;
        background-color: $color-blue;

        &:hover {
            background-color: mix($color-white, $color-blue, 10%);
        }
    }

    &--blue-dark {
        color: $color-white;
        fill: $color-white;
        background-color: $color-blue-dark;

        &:hover {
            background-color: mix($color-white, $color-blue-dark, 10%);
        }
    }

    &--grey {
        color: $color-white;
        fill: $color-white;
        background-color: $color-grey;

        &:hover {
            background-color: mix($color-white, $color-grey, 10%);
        }
    }

    &--pink {
        color: $color-white;
        fill: $color-white;
        background-color: $color-pink;

        &:hover {
            background-color: mix($color-white, $color-pink, 10%);
        }
    }

    &--amber {
        color: $color-text-main;
        fill: $color-text-main;
        background-color: $color-orange-bg;

        &:hover {
            background-color: mix($color-white, $color-orange-bg, 10%);
        }
    }

    &--red {
        color: $color-white;
        fill: $color-white;
        background-color: $color-red;

        &:hover {
            background-color: mix($color-white, $color-red, 10%);
        }
    }

    &--red-light {
        background-color: mix($color-white, $color-red, 70%);
        fill: $color-red;
        color: $color-red;

        &:hover {
            background-color: mix($color-white, $color-red, 50%);
        }
    }

    &--disabled {
        opacity: 0.5;
        pointer-events: none;

        &:hover {
            opacity: 0.5;
            cursor: default;
        }
    }

    &--margin-right {
        margin-right: 10px;
    }
}

.v2__button-icon,
.v2__button-icon-right {
    display: flex;
}

.v2__button--small.v2__button--name-icon .v2__button-icon > svg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
}

.v2__button--small.v2__button--name-icon .v2__button-icon-right > svg {
    width: 14px;
    height: 14px;
    margin-left: 4px;
}

.v2__button--small.v2__button--only-icon .v2__button-icon > svg {
    margin: -2px;
    width: 18px;
    height: 18px;
}

.v2__button--medium.v2__button--name-icon .v2__button-icon > svg {
    width: 18px;
    height: 18px;
    margin-right: 4px;
}

.v2__button--medium.v2__button--name-icon .v2__button-icon-right > svg {
    width: 18px;
    height: 18px;
    margin-left: 4px;
}

.v2__button--medium.v2__button--only-icon .v2__button-icon > svg {
    margin: -2px;
    width: 22px;
    height: 22px;
}

.v2__button--small .v2__button-name {
    @include font-p;
    line-height: 14px;
}

.v2__button--medium .v2__button-name {
    @include font-h4;
}

.v2__button--vertical .v2__button-name {
    @include font-h4;
    font-weight: 600;
}

.v2__button--vertical.v2__button--name-icon .v2__button-icon > svg {
    width: 36px;
    height: 36px;
}

.v2-popover-as-button {
    color: $color-text-main!important;
    fill: $color-text-main!important;
    background-color: $color-white!important;
    border-color: $color-border-main!important;
    border-radius: 5px;
    margin: 5px;
    height: 40px;
    .bp5-button-text {
        font-family: "Mulish", sans-serif;
        font-weight: 600;
        font-size: 14px;
    }
}

.v2__button-container {
    display: flex;

    &--justify-end {
        justify-content: flex-end;
    }
}